
import { ref } from 'vue'
import { debitNote , creditNote , invoice , creditNote_ , debitNote_ } from '../../../shared/constants/documents'
import { useStore } from "vuex";
const useDocument = () => {
  let select = ref(true);
  let isShow = ref(false);
  let typeModal = ref("result");
  let type = ref("success");

  const messageForm = ref({
      message: '',
      messageType: '',
  });
  const resetValues = () => {
      messageForm.value.message = '';
      messageForm.value.messageType = '';
  };

  const showOrClose = () =>{
    isShow.value = !isShow.value
  }

  const isLoadingDocument = ref(false);
  const store = useStore();

  const getDocument = async (type,folio,typeDocument,ruc,tipoEmpresa) =>{
    if(tipoEmpresa === "faber")
      tipoEmpresa = "Faber"
    if(tipoEmpresa === "vikingo")
      tipoEmpresa = "Vikingo"
    resetValues()
    isLoadingDocument.value = true
    let typeDocumentValue = typeDocument === debitNote || typeDocument === debitNote_ ? "08" : typeDocument === creditNote || typeDocument === creditNote_ ? "07": typeDocument === invoice  ? "01" : "03";
    const response = await store.dispatch('user/getDocument',{ type , folio , typeDocumentValue , tipoEmpresa , ruc })
    isLoadingDocument.value = false
    try{
      let url = new URL(response.link);
      let link = document.createElement("a");
      link.setAttribute('target','_BLANK');
      link.setAttribute("href", url.href);
      
      link.setAttribute("download", "file");
      messageForm.value.messageType = 'success';
      messageForm.value.message = "Documento descargado";
      link.click();
    }catch(err){
      messageForm.value.messageType = 'error';
      isShow.value = true;
      messageForm.value.message = response.link;
    }
  }
  return {
    isLoadingDocument,
    getDocument,
    messageForm,
    isShow,
    select,
    type,
    typeModal,
    showOrClose

  }
};

export default useDocument;




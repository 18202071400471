import {  useStore } from 'vuex'
import { computed } from 'vue'

const useAuth = () => {
    const store = useStore()
    const auth = computed(() => store.getters["auth/getAuth"])
    const roleAdmin = "1" 
    const roleUser = ["2","3"]
    const rolesMarketing = ["5","6"]

    return {
        auth,
        rolesMarketing,
        roleUser,
        roleAdmin
    }
}

export default useAuth
<template>
  <nav class="nav text-white mt-2">
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'dashboard-billing' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'facturacion' ? 'li-active' : '']"
      >
        <i class="far fa-file-alt"></i>{{ $t('user.nav.billing') }}
      </li>
    </router-link>
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'dashboard-accounts' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'cuentas' ? 'li-active' : '']"
      >
        <i class="far fa-money-bill-alt"></i>{{ $t('user.nav.accounts') }}
      </li>
    </router-link>
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'dashboard-collection' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'cobranzas' ? 'li-active' : '']"
      >
        <i class="fas fa-hand-holding-usd"></i>{{ $t('user.nav.collection') }}
      </li>
    </router-link>
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'dashboard-other-requests' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'consultas' ? 'li-active' : '']"
      >
        <i class="fas fa-th-large"></i>{{ $t('user.nav.otherRequests') }}
      </li>
    </router-link>
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'dashboard-profile' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'mi-perfil' ? 'li-active' : '']"
      >
        <i class="fas fa-user-alt"></i>{{ $t('user.nav.profile') }}
      </li>
    </router-link>
  </nav>
</template>



<script>
    export default{
        props:{
            path: {
                type: String,
                required: true
            },
            auth: {
                type: Object,
                required: true
            },
            roleAdmin: {
                type: String,
                required: true
            }
        },
        methods: {
          gtmView(){
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-249712648-1');
            gtag('event', 'page_view', {
                page_title: '<Page Title>',
                page_location: document.URL,
                page_path: '<Page Path>',
                send_to: '<GA_MEASUREMENT_ID>'
            })
            console.log(document.URL)
          },
        },
    }
</script>
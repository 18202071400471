import { ref } from 'vue';
const useDropdown = () => {
  let selectAccount = ref('faber');
  let showInfo = ref(false);
  let className = ref('');
  let infoAccount = ref({});

  const close = () =>{
    showInfo.value = false
  }

  const setInfo = (drowpdown) =>{
    showInfo.value = true;
    className.value = drowpdown
  }

  const setInfoDropdown = (data) =>{
    if(window.innerWidth <= 900){
      setInfo('account-info')
      infoAccount.value = data
    }
   
  }
  return {
    selectAccount,
    showInfo,
    close,
    className,
    setInfo,
    setInfoDropdown,
    infoAccount
  }
};

export default useDropdown;

const months = [ 'Ene' , 'Feb' , 'Mar' , 'Abr' , 'May' , 'Jun' , 'Jul' , 'Ago' , 'Sep' , 'Oct' , 'Nov' , 'Dic' ];

const getFormatDate = (date,type = 'default') => {
    if(type === 'default'){
        return date.substr(8,2) + '/' + date.substr(5,2) + '/' + date.substr(0,4)
    }else if(type === 'behavior'){
        const month = date.substr(0,2)
        const year = date.substr(5,2)
        return months[month - 1] + '-' + year
    }
}

export default getFormatDate;